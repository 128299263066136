//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';

// bg
.bg-light-orange {
  background-color: #fdebd0;
}

.svg-icon-orange {
  color: #f5b041;
}

.badge-light-orange {
  color: #f5b041;
  background-color: #fdebd0;
}

// swal2
.swal2-cancel {
  width: 75px;
}

.swal2-confirm {
  width: 75px;
}
// end

.word-break {
  word-break: break-all;
}

#fullpage {
  display: none;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}

.input-check-box {
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -0.125rem;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  appearance: none;
  border: 2px solid #7e8299;
  border-radius: 5px;
}

.static {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9999999999;
}

.min-w-130px {
  min-width: 130px !important;
}

.min-w-120px {
  min-width: 120px !important;
}

.h-50px {
  height: 50px;
}

.h-40px {
  height: 40px;
}

.h-35px {
  height: 35px;
}

// css loader
@keyframes ldio-pojnk8my9wr {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-pojnk8my9wr div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-pojnk8my9wr linear 1s infinite;
  background: #85a2b6;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.ldio-pojnk8my9wr div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #85a2b6;
}
.ldio-pojnk8my9wr div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #85a2b6;
}
.loadingio-spinner-spinner-2c4dcz2cbtb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f300;
}
.ldio-pojnk8my9wr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-pojnk8my9wr div {
  box-sizing: content-box;
}

.bg-loading {
  position: fixed;
  z-index: 9999999;
  background: #00000040;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.box-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-table-sticky {
  position: sticky !important;
  top: 0;
  z-index: 11;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

// --- margin
.m-none {
  margin: 0 !important;
}

// --- text
.text-orange {
  color: orange;
}
